import {
  Card,
  CardActionArea,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useDailyStore } from "stores/dailyStore";

import BurndownChart from "components/BurndownChart";
import Speakers from "components/Speakers";
import Truck from "components/Truck";
import { TeamUserOutboundDto } from "generated/model";

import {
  Stopwatch,
  Timer,
  getCurrentSpeaker,
  getNextSpeaker,
  getStopwatchDiff,
  getTimerDiff,
  shouldMeetingEnd,
} from "./DailyPage.service";

const DailyPage = () => {
  const status = useDailyStore((state) => state.status);
  const dailyStore = useDailyStore();
  const [currentSpeakerIndex, setCurrentSpeakerIndex] = useState<number>();
  const [speakerStopwatch, setSpeakerStopwatch] = useState<Stopwatch | null>(
    null
  );
  const [meetingTimer, setMeetingTimer] = useState<Timer | null>(null);
  const filteredSpeakers: TeamUserOutboundDto[] = dailyStore.validatedSpeakers;
  console.log({ filteredSpeakers });
  const currentSpeaker = getCurrentSpeaker(
    filteredSpeakers,
    currentSpeakerIndex
  );
  const nextSpeaker = getNextSpeaker(filteredSpeakers, currentSpeakerIndex);

  const stopwatch = getStopwatchDiff(speakerStopwatch);
  const meetingTimerDisplay = getTimerDiff(meetingTimer);
  const shouldMeetingEndStyle = shouldMeetingEnd(meetingTimer)
    ? {
        backgroundColor: "red",
        color: "white",
      }
    : {};

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (speakerStopwatch && meetingTimer) {
        setSpeakerStopwatch({
          start: speakerStopwatch.start,
          now: DateTime.local(),
          tick: null,
        });
        setMeetingTimer({
          end: meetingTimer.end,
          now: DateTime.local(),
          tick: null,
        });
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [speakerStopwatch]);

  return (
    <Container>
      <Stack
        sx={{
          gap: 4,
        }}
      >
        <Stack direction="row" gap={2}>
          {status === "pick speakers" && <Speakers />}
          {status === "waiting for start" && (
            <Stack
              direction="row"
              flex={1}
              gap={2}
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Truck />
              <Stack gap={2} maxWidth={320}>
                <Card sx={{ flex: 1, p: 2 }} elevation={3}>
                  <Stack sx={{ height: "100%" }}>
                    <Typography variant="h6">Speaker</Typography>
                    <Typography variant="h3">{currentSpeaker}</Typography>
                    <Stack
                      flex={1}
                      justifyContent="flex-end"
                      alignItems="flex-end"
                    >
                      <Typography variant="body1">{stopwatch}</Typography>
                    </Stack>
                  </Stack>
                </Card>

                <Card elevation={3}>
                  <CardActionArea
                    sx={{
                      p: 2,
                      opacity: 0.4,
                      backgroundColor: "blue",
                      color: "white",
                    }}
                    onClick={() => {
                      setSpeakerStopwatch({
                        start: DateTime.local(),
                        now: DateTime.local(),
                        tick: null,
                      });
                      if (currentSpeakerIndex === undefined) {
                        setCurrentSpeakerIndex(0);
                        setMeetingTimer({
                          end: DateTime.local().plus({ minutes: 15 }),
                          now: DateTime.local(),
                          tick: null,
                        });

                        return;
                      }
                      if (currentSpeakerIndex < filteredSpeakers.length) {
                        setCurrentSpeakerIndex(currentSpeakerIndex + 1);

                        return;
                      }
                    }}
                  >
                    <Stack>
                      <Typography variant="h6">Next Speaker</Typography>
                      <Typography variant="h5">
                        {nextSpeaker || "Start"}
                      </Typography>
                    </Stack>
                  </CardActionArea>
                </Card>
                <Card sx={{ p: 2, ...shouldMeetingEndStyle }}>
                  <Typography variant="h3">{meetingTimerDisplay}</Typography>
                </Card>
                {/* <Tooltip title="This creates a snapshot to be able to identify problems tomorrow">
                <Button variant="contained" disabled>
                  Create snapshot
                </Button>
              </Tooltip>
              <Tooltip title="Copy the summary of the daily into an email and sends it to the whole team">
                <Button variant="contained" disabled>
                  Send email
                </Button>
              </Tooltip> */}
                <BurndownChart />
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Container>
  );
};

export default DailyPage;
