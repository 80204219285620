import { zodResolver } from "@hookform/resolvers/zod";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LoadingButton from "@mui/lab/LoadingButton";
import { Container, Stack, TextField, Typography } from "@mui/material";
import { useAuthenticationCtx } from "contexts/authenticationCtx";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { APP_BAR_HEIGHT } from "config/stylesheet";
import { useCreateSubscription } from "generated/hook";
import { CreateSubscriptionInboundDto } from "generated/model";
import { ROUTES } from "services/routes";

import { SECTIONS } from "./HomePage.service";

const schema = z.object({
  email: z.string().email("Invalid email format"),
});

const HomePage = () => {
  const authenticationCtx = useAuthenticationCtx();
  const navigate = useNavigate();
  const [hasRequestedAccess, setHasRequestedAccess] = useState(
    localStorage.getItem("hasRequestedAccess") === "true"
  );
  const { mutate: createSubscription, isLoading } = useCreateSubscription();

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm<CreateSubscriptionInboundDto>({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    if (authenticationCtx.isAuthenticated) {
      navigate(ROUTES.DAILY);
    }
  }, []);
  const onSubmit = async () => {
    const data = getValues();

    try {
      await createSubscription({ data });
      enqueueSnackbar(
        "You should receive a confirmation email in the next 5 minutes",
        { variant: "success" }
      );
    } catch (e) {
    } finally {
      localStorage.setItem("hasRequestedAccess", "true");
      setHasRequestedAccess(true);
    }
  };

  const onError = () => {
    if (errors.email?.message) {
      enqueueSnackbar(errors.email.message, { variant: "error" });
    }
  };

  return (
    <Stack>
      <Container>
        <Stack
          sx={{ height: `calc(100vh - ${APP_BAR_HEIGHT})` }}
          direction="row"
        >
          <Stack flex="1" justifyContent="center">
            <Typography variant="h1">Keep the right direction</Typography>
            <Typography variant="h6" sx={{ mb: 6 }}>
              By highlighting the important inputs and outputs of meetings to
              save everyone&apos;s time and allow people to ask the good
              questions at the right moment
            </Typography>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <Stack direction="row" gap={2}>
                {hasRequestedAccess ? (
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Typography variant="h6">
                      Thanks for requesting the access !
                    </Typography>
                    <CheckCircleOutlineIcon color="success" fontSize="large" />
                  </Stack>
                ) : (
                  <>
                    <TextField
                      label="Enter your e-mail"
                      {...register("email")}
                      disabled={isLoading}
                    />
                    <div>
                      <LoadingButton
                        loading={isLoading}
                        color="primary"
                        variant="contained"
                        sx={{ height: "100%" }}
                        type="submit"
                      >
                        Request access
                      </LoadingButton>
                    </div>
                  </>
                )}
              </Stack>
            </form>
          </Stack>
          <Stack flex="1" />
        </Stack>
        <Stack textAlign="center" my="80px">
          <Typography sx={{ mb: "80px" }} variant="h2">
            Work in full transparency
          </Typography>
          <Stack direction={{ xs: "column", md: "row" }} gap="50px">
            {SECTIONS.map((section) => (
              <Stack key={`section-${section.id}`} flex="1" gap="16px">
                <div>
                  <section.icon sx={{ height: "80px", width: "80px" }} />
                </div>
                <Typography variant="h3">{section.title}</Typography>
                <Typography variant="body1">{section.description}</Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};

export default HomePage;
