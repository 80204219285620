import { MenuItem, MenuList, Stack } from "@mui/material";

const StandardsPage = () => {
  return (
    <Stack>
      <MenuList>
        <MenuItem>Standards</MenuItem>
      </MenuList>
    </Stack>
  );
};

export default StandardsPage;
