import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  Chip,
  Container,
  IconButton,
  Stack,
} from "@mui/material";
import { useAuthenticationCtx } from "contexts/authenticationCtx";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useServerStore } from "stores/serverStore";

import {
  getGetConnectedUserQueryKey,
  useGetServerHealthCheck,
} from "generated/hook";
import { axiosClient } from "services/network";
import { ROUTES } from "services/routes";

const NavBar = () => {
  const authenticationCtx = useAuthenticationCtx();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const serverVersion = useServerStore((store) => store.version);
  const setServerVersion = useServerStore((store) => store.action.setVersion);
  useGetServerHealthCheck({
    query: {
      onSuccess: (data) => {
        setServerVersion(data.version);
      },
    },
  });

  const onLogout = async () => {
    await axiosClient({
      method: "post",
      url: "/signout",
    });
    navigate(ROUTES.HOME);
    queryClient.invalidateQueries(getGetConnectedUserQueryKey());
    queryClient.setQueryData(getGetConnectedUserQueryKey(), undefined);
  };

  useEffect(() => {
    if (
      location.pathname === ROUTES.HOME &&
      authenticationCtx.isAuthenticated
    ) {
      navigate(ROUTES.DAILY);
    }
  }, []);

  return (
    <AppBar color="primary" position="sticky">
      <Container>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          py={1}
        >
          <Stack direction="row" alignItems="center">
            <Chip
              variant="filled"
              color={serverVersion ? "success" : "warning"}
              label={serverVersion ? serverVersion : "Server Booting..."}
            />
            <Link to={ROUTES.HOME}>
              <IconButton aria-label="home" color="inherit">
                <HomeIcon />
              </IconButton>
            </Link>
            {authenticationCtx.isAuthenticated ? (
              <>
                <Link to={ROUTES.DAILY} style={{ display: "flex" }}>
                  <Button color="inherit">Daily</Button>
                </Link>
                <Link to={ROUTES.ACTIVE_SPRINT} style={{ display: "flex" }}>
                  <Button color="inherit">Weekly</Button>
                </Link>
              </>
            ) : null}
          </Stack>
          <Box
            sx={{
              display: {
                sm: "block",
                xs: "none",
              },
            }}
          >
            {authenticationCtx.isAuthenticated ? (
              <Stack>
                <Button color="inherit" onClick={onLogout}>
                  Log out
                </Button>
              </Stack>
            ) : (
              <Stack direction="row">
                <Button color="inherit" href={ROUTES.SIGN_IN}>
                  Sign In
                </Button>
              </Stack>
            )}
          </Box>
          <Box
            sx={{
              display: {
                sm: "none",
              },
            }}
          >
            <IconButton color="inherit">
              <MenuIcon />
            </IconButton>
          </Box>
        </Stack>
      </Container>
    </AppBar>
  );
};

export default NavBar;
