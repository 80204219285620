/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * ktan-stack-back
 * OpenAPI spec version: 1.0.0
 */

export type TicketsOutboundDtoStatus = typeof TicketsOutboundDtoStatus[keyof typeof TicketsOutboundDtoStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TicketsOutboundDtoStatus = {
  TO_DO: 'TO_DO',
  DOING: 'DOING',
  CODE_REVIEW: 'CODE_REVIEW',
  TO_VALIDATE: 'TO_VALIDATE',
  DONE: 'DONE',
  UNKNOWN: 'UNKNOWN',
} as const;
