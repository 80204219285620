import { Stack } from "@mui/material";
import { useAuthenticationCtx } from "contexts/authenticationCtx";
import { ReactNode } from "react";

import NavBar from "components/NavBar";

interface Props {
  children: ReactNode;
}

const Layout = (props: Props) => {
  const { children } = props;

  const authenticationCtx = useAuthenticationCtx();

  return (
    <Stack
      gap={4}
      sx={{
        minHeight: authenticationCtx.isAuthenticated
          ? "calc(100vh - 64px - 32px - 32px - 32px)"
          : "100vh",
      }}
    >
      <NavBar />
      <Stack flex={1}>{children}</Stack>
    </Stack>
  );
};

export default Layout;
