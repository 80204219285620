/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * ktan-stack-back
 * OpenAPI spec version: 1.0.0
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  GetArticlesOutboundDto,
  CreateArticle200,
  BadRequestResponse,
  CreateArticleInboundDto,
  GetArticleOutboundDto,
  GetArticlePreviewsOutboundDto,
  GetBurndownChartOutboundDto,
  CreateContactInboundDto,
  GetAllFeaturesOutboundDto,
  CreateFeature200,
  CreateFeatureInboundDto,
  UpdateFeature200,
  UpdateFeatureInboundDto,
  GetServerHealthCheckOutboundDto,
  BurndownChartColumnOutboundDto,
  GetAllTeamsOutboundDto,
  TeamUserOutboundDto,
  GetTicketsOutboundDto,
  UpsertTicketOutboundDto,
  UpsertTicketInboundDto,
  UserOutboundDto,
  CreateSubscriptionInboundDto,
  GetConnectedUser200,
  VelocityOutboundDto,
  CreateVelocity200,
  VelocityInboundDto,
  GetWorksOutboundDto,
  NotionPageContentOutboundDto
} from './model'
import { customInstance } from '../services/network';



export const getArticles = (
    
 signal?: AbortSignal
) => {
      return customInstance<GetArticlesOutboundDto[]>(
      {url: `/articles`, method: 'get', signal
    },
      );
    }
  

export const getGetArticlesQueryKey = () => [`/articles`] as const;
  

    
export const getGetArticlesQueryOptions = <TData = Awaited<ReturnType<typeof getArticles>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getArticles>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof getArticles>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetArticlesQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getArticles>>> = ({ signal }) => getArticles(signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetArticlesQueryResult = NonNullable<Awaited<ReturnType<typeof getArticles>>>
export type GetArticlesQueryError = unknown

export const useGetArticles = <TData = Awaited<ReturnType<typeof getArticles>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getArticles>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetArticlesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const createArticle = (
    createArticleInboundDto: CreateArticleInboundDto,
 ) => {
      return customInstance<CreateArticle200>(
      {url: `/articles`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createArticleInboundDto
    },
      );
    }
  


export const getCreateArticleMutationOptions = <TError = BadRequestResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createArticle>>, TError,{data: CreateArticleInboundDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createArticle>>, TError,{data: CreateArticleInboundDto}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createArticle>>, {data: CreateArticleInboundDto}> = (props) => {
          const {data} = props ?? {};

          return  createArticle(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateArticleMutationResult = NonNullable<Awaited<ReturnType<typeof createArticle>>>
    export type CreateArticleMutationBody = CreateArticleInboundDto
    export type CreateArticleMutationError = BadRequestResponse

    export const useCreateArticle = <TError = BadRequestResponse,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createArticle>>, TError,{data: CreateArticleInboundDto}, TContext>, }
) => {
    
      const mutationOptions = getCreateArticleMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const getArticle = (
    articleId: string,
 signal?: AbortSignal
) => {
      return customInstance<GetArticleOutboundDto>(
      {url: `/articles/${articleId}`, method: 'get', signal
    },
      );
    }
  

export const getGetArticleQueryKey = (articleId: string,) => [`/articles/${articleId}`] as const;
  

    
export const getGetArticleQueryOptions = <TData = Awaited<ReturnType<typeof getArticle>>, TError = unknown>(articleId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getArticle>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof getArticle>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetArticleQueryKey(articleId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getArticle>>> = ({ signal }) => getArticle(articleId, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(articleId), ...queryOptions}}

export type GetArticleQueryResult = NonNullable<Awaited<ReturnType<typeof getArticle>>>
export type GetArticleQueryError = unknown

export const useGetArticle = <TData = Awaited<ReturnType<typeof getArticle>>, TError = unknown>(
 articleId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getArticle>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetArticleQueryOptions(articleId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const getArticlePreviews = (
    
 signal?: AbortSignal
) => {
      return customInstance<GetArticlePreviewsOutboundDto[]>(
      {url: `/article-previews`, method: 'get', signal
    },
      );
    }
  

export const getGetArticlePreviewsQueryKey = () => [`/article-previews`] as const;
  

    
export const getGetArticlePreviewsQueryOptions = <TData = Awaited<ReturnType<typeof getArticlePreviews>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getArticlePreviews>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof getArticlePreviews>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetArticlePreviewsQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getArticlePreviews>>> = ({ signal }) => getArticlePreviews(signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetArticlePreviewsQueryResult = NonNullable<Awaited<ReturnType<typeof getArticlePreviews>>>
export type GetArticlePreviewsQueryError = unknown

export const useGetArticlePreviews = <TData = Awaited<ReturnType<typeof getArticlePreviews>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getArticlePreviews>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetArticlePreviewsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const getBurndownCharts = (
    
 signal?: AbortSignal
) => {
      return customInstance<GetBurndownChartOutboundDto[]>(
      {url: `/burndown-charts`, method: 'get', signal
    },
      );
    }
  

export const getGetBurndownChartsQueryKey = () => [`/burndown-charts`] as const;
  

    
export const getGetBurndownChartsQueryOptions = <TData = Awaited<ReturnType<typeof getBurndownCharts>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBurndownCharts>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof getBurndownCharts>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetBurndownChartsQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getBurndownCharts>>> = ({ signal }) => getBurndownCharts(signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetBurndownChartsQueryResult = NonNullable<Awaited<ReturnType<typeof getBurndownCharts>>>
export type GetBurndownChartsQueryError = unknown

export const useGetBurndownCharts = <TData = Awaited<ReturnType<typeof getBurndownCharts>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBurndownCharts>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetBurndownChartsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const createContacts = (
    createContactInboundDto: CreateContactInboundDto,
 ) => {
      return customInstance<void>(
      {url: `/contacts`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createContactInboundDto
    },
      );
    }
  


export const getCreateContactsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createContacts>>, TError,{data: CreateContactInboundDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createContacts>>, TError,{data: CreateContactInboundDto}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createContacts>>, {data: CreateContactInboundDto}> = (props) => {
          const {data} = props ?? {};

          return  createContacts(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateContactsMutationResult = NonNullable<Awaited<ReturnType<typeof createContacts>>>
    export type CreateContactsMutationBody = CreateContactInboundDto
    export type CreateContactsMutationError = unknown

    export const useCreateContacts = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createContacts>>, TError,{data: CreateContactInboundDto}, TContext>, }
) => {
    
      const mutationOptions = getCreateContactsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const getAllFeatures = (
    
 signal?: AbortSignal
) => {
      return customInstance<GetAllFeaturesOutboundDto[]>(
      {url: `/features`, method: 'get', signal
    },
      );
    }
  

export const getGetAllFeaturesQueryKey = () => [`/features`] as const;
  

    
export const getGetAllFeaturesQueryOptions = <TData = Awaited<ReturnType<typeof getAllFeatures>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllFeatures>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof getAllFeatures>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllFeaturesQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllFeatures>>> = ({ signal }) => getAllFeatures(signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetAllFeaturesQueryResult = NonNullable<Awaited<ReturnType<typeof getAllFeatures>>>
export type GetAllFeaturesQueryError = unknown

export const useGetAllFeatures = <TData = Awaited<ReturnType<typeof getAllFeatures>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllFeatures>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAllFeaturesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const createFeature = (
    createFeatureInboundDto: CreateFeatureInboundDto,
 ) => {
      return customInstance<CreateFeature200>(
      {url: `/features`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createFeatureInboundDto
    },
      );
    }
  


export const getCreateFeatureMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createFeature>>, TError,{data: CreateFeatureInboundDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createFeature>>, TError,{data: CreateFeatureInboundDto}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createFeature>>, {data: CreateFeatureInboundDto}> = (props) => {
          const {data} = props ?? {};

          return  createFeature(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateFeatureMutationResult = NonNullable<Awaited<ReturnType<typeof createFeature>>>
    export type CreateFeatureMutationBody = CreateFeatureInboundDto
    export type CreateFeatureMutationError = unknown

    export const useCreateFeature = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createFeature>>, TError,{data: CreateFeatureInboundDto}, TContext>, }
) => {
    
      const mutationOptions = getCreateFeatureMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const updateFeature = (
    featureId: string,
    updateFeatureInboundDto: UpdateFeatureInboundDto,
 ) => {
      return customInstance<UpdateFeature200>(
      {url: `/features/${featureId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateFeatureInboundDto
    },
      );
    }
  


export const getUpdateFeatureMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateFeature>>, TError,{featureId: string;data: UpdateFeatureInboundDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateFeature>>, TError,{featureId: string;data: UpdateFeatureInboundDto}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateFeature>>, {featureId: string;data: UpdateFeatureInboundDto}> = (props) => {
          const {featureId,data} = props ?? {};

          return  updateFeature(featureId,data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateFeatureMutationResult = NonNullable<Awaited<ReturnType<typeof updateFeature>>>
    export type UpdateFeatureMutationBody = UpdateFeatureInboundDto
    export type UpdateFeatureMutationError = unknown

    export const useUpdateFeature = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateFeature>>, TError,{featureId: string;data: UpdateFeatureInboundDto}, TContext>, }
) => {
    
      const mutationOptions = getUpdateFeatureMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const deleteFeature = (
    featureId: string,
 ) => {
      return customInstance<void>(
      {url: `/features/${featureId}`, method: 'delete'
    },
      );
    }
  


export const getDeleteFeatureMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFeature>>, TError,{featureId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteFeature>>, TError,{featureId: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFeature>>, {featureId: string}> = (props) => {
          const {featureId} = props ?? {};

          return  deleteFeature(featureId,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteFeatureMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFeature>>>
    
    export type DeleteFeatureMutationError = unknown

    export const useDeleteFeature = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFeature>>, TError,{featureId: string}, TContext>, }
) => {
    
      const mutationOptions = getDeleteFeatureMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const getServerHealthCheck = (
    
 signal?: AbortSignal
) => {
      return customInstance<GetServerHealthCheckOutboundDto>(
      {url: `/server/health-check`, method: 'get', signal
    },
      );
    }
  

export const getGetServerHealthCheckQueryKey = () => [`/server/health-check`] as const;
  

    
export const getGetServerHealthCheckQueryOptions = <TData = Awaited<ReturnType<typeof getServerHealthCheck>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getServerHealthCheck>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof getServerHealthCheck>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetServerHealthCheckQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getServerHealthCheck>>> = ({ signal }) => getServerHealthCheck(signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetServerHealthCheckQueryResult = NonNullable<Awaited<ReturnType<typeof getServerHealthCheck>>>
export type GetServerHealthCheckQueryError = unknown

export const useGetServerHealthCheck = <TData = Awaited<ReturnType<typeof getServerHealthCheck>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getServerHealthCheck>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetServerHealthCheckQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const getActiveSprintBurndownChart = (
    
 signal?: AbortSignal
) => {
      return customInstance<BurndownChartColumnOutboundDto[]>(
      {url: `/sprints`, method: 'get', signal
    },
      );
    }
  

export const getGetActiveSprintBurndownChartQueryKey = () => [`/sprints`] as const;
  

    
export const getGetActiveSprintBurndownChartQueryOptions = <TData = Awaited<ReturnType<typeof getActiveSprintBurndownChart>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getActiveSprintBurndownChart>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof getActiveSprintBurndownChart>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetActiveSprintBurndownChartQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getActiveSprintBurndownChart>>> = ({ signal }) => getActiveSprintBurndownChart(signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetActiveSprintBurndownChartQueryResult = NonNullable<Awaited<ReturnType<typeof getActiveSprintBurndownChart>>>
export type GetActiveSprintBurndownChartQueryError = unknown

export const useGetActiveSprintBurndownChart = <TData = Awaited<ReturnType<typeof getActiveSprintBurndownChart>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getActiveSprintBurndownChart>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetActiveSprintBurndownChartQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const createStripeProduct = (
    
 ) => {
      return customInstance<string>(
      {url: `/stripes`, method: 'post'
    },
      );
    }
  


export const getCreateStripeProductMutationOptions = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createStripeProduct>>, TError,TVariables, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createStripeProduct>>, TError,TVariables, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createStripeProduct>>, TVariables> = () => {
          

          return  createStripeProduct()
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateStripeProductMutationResult = NonNullable<Awaited<ReturnType<typeof createStripeProduct>>>
    
    export type CreateStripeProductMutationError = unknown

    export const useCreateStripeProduct = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createStripeProduct>>, TError,TVariables, TContext>, }
) => {
    
      const mutationOptions = getCreateStripeProductMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const getAllTeams = (
    
 signal?: AbortSignal
) => {
      return customInstance<GetAllTeamsOutboundDto[]>(
      {url: `/teams`, method: 'get', signal
    },
      );
    }
  

export const getGetAllTeamsQueryKey = () => [`/teams`] as const;
  

    
export const getGetAllTeamsQueryOptions = <TData = Awaited<ReturnType<typeof getAllTeams>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllTeams>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof getAllTeams>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllTeamsQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllTeams>>> = ({ signal }) => getAllTeams(signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetAllTeamsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllTeams>>>
export type GetAllTeamsQueryError = unknown

export const useGetAllTeams = <TData = Awaited<ReturnType<typeof getAllTeams>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllTeams>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAllTeamsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const getTeamUsers = (
    
 signal?: AbortSignal
) => {
      return customInstance<TeamUserOutboundDto[]>(
      {url: `/team-users`, method: 'get', signal
    },
      );
    }
  

export const getGetTeamUsersQueryKey = () => [`/team-users`] as const;
  

    
export const getGetTeamUsersQueryOptions = <TData = Awaited<ReturnType<typeof getTeamUsers>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTeamUsers>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof getTeamUsers>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTeamUsersQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTeamUsers>>> = ({ signal }) => getTeamUsers(signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetTeamUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getTeamUsers>>>
export type GetTeamUsersQueryError = unknown

export const useGetTeamUsers = <TData = Awaited<ReturnType<typeof getTeamUsers>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTeamUsers>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTeamUsersQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const getTickets = (
    
 signal?: AbortSignal
) => {
      return customInstance<GetTicketsOutboundDto[]>(
      {url: `/tickets`, method: 'get', signal
    },
      );
    }
  

export const getGetTicketsQueryKey = () => [`/tickets`] as const;
  

    
export const getGetTicketsQueryOptions = <TData = Awaited<ReturnType<typeof getTickets>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTickets>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof getTickets>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTicketsQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTickets>>> = ({ signal }) => getTickets(signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetTicketsQueryResult = NonNullable<Awaited<ReturnType<typeof getTickets>>>
export type GetTicketsQueryError = unknown

export const useGetTickets = <TData = Awaited<ReturnType<typeof getTickets>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTickets>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTicketsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const upsertTickets = (
    upsertTicketInboundDto: UpsertTicketInboundDto[],
 ) => {
      return customInstance<UpsertTicketOutboundDto>(
      {url: `/tickets`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: upsertTicketInboundDto
    },
      );
    }
  


export const getUpsertTicketsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof upsertTickets>>, TError,{data: UpsertTicketInboundDto[]}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof upsertTickets>>, TError,{data: UpsertTicketInboundDto[]}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof upsertTickets>>, {data: UpsertTicketInboundDto[]}> = (props) => {
          const {data} = props ?? {};

          return  upsertTickets(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpsertTicketsMutationResult = NonNullable<Awaited<ReturnType<typeof upsertTickets>>>
    export type UpsertTicketsMutationBody = UpsertTicketInboundDto[]
    export type UpsertTicketsMutationError = unknown

    export const useUpsertTickets = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof upsertTickets>>, TError,{data: UpsertTicketInboundDto[]}, TContext>, }
) => {
    
      const mutationOptions = getUpsertTicketsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const getUsers = (
    
 signal?: AbortSignal
) => {
      return customInstance<UserOutboundDto[]>(
      {url: `/users`, method: 'get', signal
    },
      );
    }
  

export const getGetUsersQueryKey = () => [`/users`] as const;
  

    
export const getGetUsersQueryOptions = <TData = Awaited<ReturnType<typeof getUsers>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsers>>> = ({ signal }) => getUsers(signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getUsers>>>
export type GetUsersQueryError = unknown

export const useGetUsers = <TData = Awaited<ReturnType<typeof getUsers>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const createSubscription = (
    createSubscriptionInboundDto: CreateSubscriptionInboundDto,
 ) => {
      return customInstance<string>(
      {url: `/users/subscribe`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createSubscriptionInboundDto
    },
      );
    }
  


export const getCreateSubscriptionMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSubscription>>, TError,{data: CreateSubscriptionInboundDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createSubscription>>, TError,{data: CreateSubscriptionInboundDto}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createSubscription>>, {data: CreateSubscriptionInboundDto}> = (props) => {
          const {data} = props ?? {};

          return  createSubscription(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateSubscriptionMutationResult = NonNullable<Awaited<ReturnType<typeof createSubscription>>>
    export type CreateSubscriptionMutationBody = CreateSubscriptionInboundDto
    export type CreateSubscriptionMutationError = unknown

    export const useCreateSubscription = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSubscription>>, TError,{data: CreateSubscriptionInboundDto}, TContext>, }
) => {
    
      const mutationOptions = getCreateSubscriptionMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const getConnectedUser = (
    
 signal?: AbortSignal
) => {
      return customInstance<GetConnectedUser200>(
      {url: `/users/me`, method: 'get', signal
    },
      );
    }
  

export const getGetConnectedUserQueryKey = () => [`/users/me`] as const;
  

    
export const getGetConnectedUserQueryOptions = <TData = Awaited<ReturnType<typeof getConnectedUser>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConnectedUser>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof getConnectedUser>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConnectedUserQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getConnectedUser>>> = ({ signal }) => getConnectedUser(signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetConnectedUserQueryResult = NonNullable<Awaited<ReturnType<typeof getConnectedUser>>>
export type GetConnectedUserQueryError = unknown

export const useGetConnectedUser = <TData = Awaited<ReturnType<typeof getConnectedUser>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConnectedUser>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetConnectedUserQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const getVelocities = (
    
 signal?: AbortSignal
) => {
      return customInstance<VelocityOutboundDto[]>(
      {url: `/velocities`, method: 'get', signal
    },
      );
    }
  

export const getGetVelocitiesQueryKey = () => [`/velocities`] as const;
  

    
export const getGetVelocitiesQueryOptions = <TData = Awaited<ReturnType<typeof getVelocities>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getVelocities>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof getVelocities>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetVelocitiesQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getVelocities>>> = ({ signal }) => getVelocities(signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetVelocitiesQueryResult = NonNullable<Awaited<ReturnType<typeof getVelocities>>>
export type GetVelocitiesQueryError = unknown

export const useGetVelocities = <TData = Awaited<ReturnType<typeof getVelocities>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getVelocities>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetVelocitiesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const createVelocity = (
    velocityInboundDto: VelocityInboundDto,
 ) => {
      return customInstance<CreateVelocity200>(
      {url: `/velocities`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: velocityInboundDto
    },
      );
    }
  


export const getCreateVelocityMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createVelocity>>, TError,{data: VelocityInboundDto}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createVelocity>>, TError,{data: VelocityInboundDto}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createVelocity>>, {data: VelocityInboundDto}> = (props) => {
          const {data} = props ?? {};

          return  createVelocity(data,)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateVelocityMutationResult = NonNullable<Awaited<ReturnType<typeof createVelocity>>>
    export type CreateVelocityMutationBody = VelocityInboundDto
    export type CreateVelocityMutationError = unknown

    export const useCreateVelocity = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createVelocity>>, TError,{data: VelocityInboundDto}, TContext>, }
) => {
    
      const mutationOptions = getCreateVelocityMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const getWorks = (
    
 signal?: AbortSignal
) => {
      return customInstance<GetWorksOutboundDto>(
      {url: `/works`, method: 'get', signal
    },
      );
    }
  

export const getGetWorksQueryKey = () => [`/works`] as const;
  

    
export const getGetWorksQueryOptions = <TData = Awaited<ReturnType<typeof getWorks>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorks>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof getWorks>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorksQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorks>>> = ({ signal }) => getWorks(signal);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetWorksQueryResult = NonNullable<Awaited<ReturnType<typeof getWorks>>>
export type GetWorksQueryError = unknown

export const useGetWorks = <TData = Awaited<ReturnType<typeof getWorks>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorks>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetWorksQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


export const getWork = (
    workId: string,
 signal?: AbortSignal
) => {
      return customInstance<NotionPageContentOutboundDto[]>(
      {url: `/works/${workId}`, method: 'get', signal
    },
      );
    }
  

export const getGetWorkQueryKey = (workId: string,) => [`/works/${workId}`] as const;
  

    
export const getGetWorkQueryOptions = <TData = Awaited<ReturnType<typeof getWork>>, TError = unknown>(workId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWork>>, TError, TData>, }
): UseQueryOptions<Awaited<ReturnType<typeof getWork>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkQueryKey(workId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWork>>> = ({ signal }) => getWork(workId, signal);
    
      
      
   return  { queryKey, queryFn, enabled: !!(workId), ...queryOptions}}

export type GetWorkQueryResult = NonNullable<Awaited<ReturnType<typeof getWork>>>
export type GetWorkQueryError = unknown

export const useGetWork = <TData = Awaited<ReturnType<typeof getWork>>, TError = unknown>(
 workId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWork>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetWorkQueryOptions(workId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
}


