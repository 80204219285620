import { create } from "zustand";

type ServerStoreAction = {
  setEnvironment: (server: string) => void;
  setVersion: (version: string) => void;
};

type ServerStore = {
  version?: string;
  environment?: string;
  action: ServerStoreAction;
};

export const useServerStore = create<ServerStore>((set) => ({
  environment: undefined,
  version: undefined,
  action: {
    setEnvironment: (environment) => set({ environment }),
    setVersion: (version) => set({ version }),
  },
}));
