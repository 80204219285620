import CastleIcon from "@mui/icons-material/Castle";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import VisibilityIcon from "@mui/icons-material/Visibility";

export const SECTIONS = [
  {
    id: "1",
    icon: NotificationsActiveIcon,
    title: "Detect issues as soon as possible",
    description:
      "Log and track your daily meetings, making it easy to identify any issues or challenges that arise to address any potential problems before they escalate",
  },
  {
    id: "2",
    icon: VisibilityIcon,
    title: "Give visibility quickly and regularly",
    description:
      "Generate detailed reports based on meeting data to provide valuable insights into your team's productivity",
  },
  {
    id: "3",
    icon: CastleIcon,
    title: "Benefits from experienced team in software engineering",
    description:
      "Help promote best practices and build a strong collaborative team",
  },
];
