import { ThemeProvider } from "@mui/material";
import { AuthenticationCtxProvider } from "contexts/authenticationCtx";
import { SnackbarProvider } from "notistack";
import { QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Layout from "components/Layout";
import { customTheme } from "config/stylesheet";
import DailyPage from "pages/DailyPage/DailyPage";
import HomePage from "pages/HomePage";
import SignInPage from "pages/SignInPage";
import SprintPage from "pages/SprintPage";
import SprintsPage from "pages/SprintsPage";
import StandardsPage from "pages/StandardsPage.tsx/StandardsPage";
import { queryClient } from "services/reactQuery";
import { ROUTES } from "services/routes";

const App = () => {
  return (
    <ThemeProvider theme={customTheme}>
      <QueryClientProvider client={queryClient}>
        <AuthenticationCtxProvider>
          <SnackbarProvider maxSnack={3}>
            <BrowserRouter>
              <Layout>
                <Routes>
                  <Route path={ROUTES.HOME} element={<HomePage />} />
                  <Route path={ROUTES.SIGN_IN} element={<SignInPage />} />
                  <Route path={ROUTES.DAILY} element={<DailyPage />} />
                  <Route path={ROUTES.STANDARDS} element={<StandardsPage />} />
                  <Route path={ROUTES.ACTIVE_SPRINT} element={<SprintPage />} />
                  <Route path={ROUTES.SPRINTS} element={<SprintsPage />} />
                </Routes>
              </Layout>
            </BrowserRouter>
          </SnackbarProvider>
        </AuthenticationCtxProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
