import { create } from "zustand";

import { TeamUserOutboundDto } from "generated/model";
import { shuffle } from "services/array";

type DailyStatus =
  | "pick speakers"
  | "waiting for start"
  | "started to speak"
  | "questions"
  | "over";

interface DailyStoreAction {
  restore: () => void;
  setSpeakers: () => void;
  setShuffledSpeakers: (speakers: TeamUserOutboundDto[]) => void;
  setOrderedSpeakers: (speakers: TeamUserOutboundDto[]) => void;
  setValidatedSpeakers: (speakers: TeamUserOutboundDto[]) => void;
  setStatus: (status: DailyStatus) => void;
}

interface DailyStore {
  speakers: TeamUserOutboundDto[];
  validatedSpeakers: TeamUserOutboundDto[];
  orderedSpeakers: TeamUserOutboundDto[];
  shuffledSpeakers: TeamUserOutboundDto[];
  status: DailyStatus;
  actions: DailyStoreAction;
}

export const useDailyStore = create<DailyStore>((set) => ({
  speakers: [],
  validatedSpeakers: [],
  shuffledSpeakers: [],
  orderedSpeakers: [],
  status: "pick speakers",
  actions: {
    restore: () => set({ speakers: [] }),
    setSpeakers: () => set({ speakers: [] }),
    setShuffledSpeakers: (speakers: TeamUserOutboundDto[]) =>
      set({ speakers: shuffle(speakers) }),
    setValidatedSpeakers: (speakers: TeamUserOutboundDto[]) =>
      set({ validatedSpeakers: speakers }),
    setOrderedSpeakers: (speakers: TeamUserOutboundDto[]) =>
      set({ orderedSpeakers: speakers }),
    setStatus: (status: DailyStatus) => set({ status }),
  },
}));
