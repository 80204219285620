import { ReactNode, createContext, useContext } from "react";

import { useGetConnectedUser } from "generated/hook";
import { GetConnectedUser200 } from "generated/model";

interface AuthenticationContext {
  isAuthenticated: boolean;
  connectedUser?: GetConnectedUser200;
}

export const AuthenticationCtx = createContext<AuthenticationContext>({
  isAuthenticated: false,
  connectedUser: undefined,
});

export const useAuthenticationCtx = () => useContext(AuthenticationCtx);

interface Props {
  children: ReactNode;
}

export const AuthenticationCtxProvider = (props: Props) => {
  const { children } = props;

  const { data } = useGetConnectedUser();

  return (
    <AuthenticationCtx.Provider
      value={{
        isAuthenticated: !!data,
        connectedUser: data,
      }}
    >
      {children}
    </AuthenticationCtx.Provider>
  );
};
